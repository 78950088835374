<template>
  <div  class="chart">
    <div class="title">
      <div>
        <span style="margin-right: 15px;">交易金额(元)</span>
        <el-dropdown  @command="handleCommand">
          <span class="el-dropdown-link">
            {{dayStr}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="item"
                              v-for="item in dayList"
                              :key="item.type"
                              :command="item.type"
            >{{item.text}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-tooltip placement="bottom">
        <div slot="content" style="line-height: 1.5rem">
          ① 今日指的是今日已支付商品订单的交易金额；<br/>
          ② 昨日指的是昨日已支付商品订单的交易金额；<br/>
          ③ 近7日指的是近7天已支付商品订单的交易金额；<br/>
          ④ 近1个月指的是近1个月已支付商品订单的交易金额。<br/>
        </div>
        <i class="el-icon-warning-outline" ></i>
      </el-tooltip>
    </div>
    <div class="num">{{data.value||0}}</div>
    <div id="tradeAmount"></div>
    <div class="bottom">
      {{data.ratio?'较昨日 '+data.ratio:''}}
    </div>
  </div>
</template>

<script>
import {Column} from "@antv/g2plot";

export default {
  props:{
    loading: {type: Boolean},
  },
  data(){
    return{
      dayList: [
        {type: 1, text: "今日"},
        {type: 2, text: "昨天"},
        {type: 3, text: "近7天"},
        {type: 4, text: "近1个月"},
      ],
      dayStr:'今日',
      type:1,
      data:{},
      tradeAmount:null,
    }
  },
  created() {
    this.handleCommand(1)
  },
  mounted() {
    this.changeChart()
    addEventListener('resize',()=>{
      this.changeChart()
    },true)
  },
  methods:{
    getGoodOrderAmount(){
      this.$curl.get('/hm/homePage/goodOrderAmount/' + this.type).then(res=>{
        this.data = res.data
      })
    },
    handleCommand(command) {
      let day = this.dayList.find((item) => item.type === command)
      if(day) {
        this.dayStr = day.text
        this.type = day.type
        this.getGoodOrderAmount()
      }
    },
    async changeChart() {
      const data = [
        { letter: '1', value: 5  },
        { letter: '2', value: 3  },
        { letter: '3', value: 2  },
        { letter: '4', value: 2.8  },
        { letter: '5', value: 1  },
        { letter: '6', value: 1.8  },
        { letter: '7', value: 3  },
        { letter: '8', value: 6  },
        { letter: '9', value: 1  },
        { letter: '10', value: 3.2 },
        { letter: '11', value: 0.3 },
        { letter: '12', value: 2 },
        { letter: '13', value: 2.8 },
        { letter: '14', value: 4.1 },
        { letter: '15', value: 3 },
        { letter: '16', value: 3.8 },
        { letter: '17', value: 6 },
        { letter: '18', value: 4 },
      ];
      this.tradeAmount && this.tradeAmount.destroy()
      this.tradeAmount = new Column('tradeAmount', {
        data:data,
        tooltip: false,
        color: '#FD6458',
        xField: 'letter',
        yField: 'value',
        padding:10,
        maxColumnWidth:30,
        xAxis: {
          label: null,
          line:null
        },
        yAxis: {
          label: null,
          grid:null
        },
      });
      this.tradeAmount.render();
      this.$emit('update:loading', false)
    }
  }
}
</script>



<style scoped lang="scss">
.chart{
  position: relative;
}
.title{
  color: #666666;
  margin-left: 10px;
  margin-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.num{
  font-size: 32px;
  font-weight: bold;
  margin-left: 10px;
}
#tradeAmount{
  width: 100%;
  height: 60px;
}
.bottom{
  border-top: 1px solid #E8E8E8;
  padding-top: 5px;
  margin: 0 18px;
  font-size: 14px;
}
.el-dropdown-menu__item {
  padding: 0 18px 0 10px;
}
</style>